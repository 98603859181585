// ** Logo
import logo from "@src/assets/images/logo/FAI-short.png"
import Spinner from "reactstrap/lib/Spinner"
// Edit css in here: assessments.fai-farms.com\src\@core\scss\react\app-loader.scss

const SpinnerComponent = () => {
  return (
    <div className="fallback-spinner vh-100">
      <img className="fallback-logo mb-3" src={logo} alt="logo" />
      <Spinner
        className="mx-auto mb-auto mt-0"
        style={{ width: "3rem", height: "3rem" }}
        color="primary"
      />
    </div>
  )
}

export default SpinnerComponent
